@include media-breakpoint-up(lg) {
  .cb-wrapper {
    &.full-width {
      .cb-banner__html {
        h2 {
          @include font-size(2.25rem);
        }
      }
    }

    &.top-image {
      display: flex;
      height: 100%;
    }
  }

  .cb-banner {
    &.full-width {
      .cb-banner__content {
        aspect-ratio: 3/1;
      }

      .cb-banner__html-content {
        width: 50%;
      }
    }

    &.content-center {
      .cb-banner__html-content {
        margin-right: auto;
        margin-left: auto;
        text-align: center;
      }
    }

    &.content-right {
      .cb-banner__html-content {
        margin-left: auto;
      }
    }
  }
}

.cb-banner__content {
  position: relative;
  aspect-ratio: 16/10;
}

.cb-banner__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cb-banner__image-container {
  position: relative;
  text-align: center;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 50%;
    content: "";
    background-color: $light;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.cb-banner__html {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: .875rem;
  font-weight: 300;
  color: $white;
  text-align: center;
  background-color: rgba($black, .25);

  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
    text-align: left;
  }

  h2 {
    @include font-size(1.875rem);
    margin-bottom: .75rem;
    text-transform: uppercase;
  }

  a {
    @include transition($btn-transition);
    display: inline-block;
    padding: $btn-padding-y $btn-padding-x;
    margin-top: .5rem;
    font-family: $btn-font-family;
    font-size: $btn-font-size;
    font-weight: $btn-font-weight;
    line-height: $btn-line-height;
    color: inherit;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: $btn-border-width solid currentcolor;
    border-radius: .25rem;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
    }

    &:hover {
      color: $xx-cycle-yellow;
      border-color: $xx-cycle-yellow;
    }
  }
}

.cb-banner__html-content {
  padding: 2rem 1rem;

  @include media-breakpoint-up(sm) {
    padding: 2rem;
  }

  @include media-breakpoint-up(xl) {
    padding: 2.5rem;
  }

  > :last-child {
    margin-bottom: 0;
  }

  p {
    &:not(:last-child) {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }
}

.top-image {
  .cb-banner {
    display: flex;
    height: 100%;
  }

  .cb-banner__content {
    display: flex;
    flex-direction: column;
    aspect-ratio: inherit;
  }

  .cb-banner__html {
    position: relative;
    align-items: initial;
    color: $secondary;
    background-color: $light;
  }

  .cb-banner__html-content {
    display: flex;
    flex-direction: column;
  }

  .cb-banner__image {
    width: 80%;
    height: auto;
    border-radius: 5px;

    @include media-breakpoint-up(xl) {
      width: inherit;
    }
  }

  h2 {
    margin-bottom: 2rem;
  }

  p {
    text-align: left;

    &:last-child {
      margin-top: auto;
      text-align: center;

      @include media-breakpoint-up(lg) {
        text-align: left;
      }
    }
  }
}

.new-discounted-banner {
  margin-bottom: 3rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 5rem;
  }

  .cb-banner__content {
    aspect-ratio: 16/5;

    @include media-breakpoint-up(md) {
      aspect-ratio: 16/4;
    }
  }

  .cb-banner__html {
    background-color: none;
  }
}
